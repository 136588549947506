<template>
  <card-container class="card-side">
    <div class="flex flex-col h-full p-5">
      <!-- Navigation top -->
      <div class="flex">
        <button
          @click="$router.push({ name: 'location', params: { transition: 'spin-backward' } })"
          class="flex-none w-10 h-10 bg-gray-200 rounded-full"
        >
          <i class="fa-light fa-angle-left fa-lg"></i>
        </button>
        <div class="flex-grow"></div>
        <button
          @click="$router.push({ name: 'home', params: { transition: 'zoom-in' } })"
          class="flex-none w-10 h-10 bg-gray-200 rounded-full"
        >
          <i class="fa-light fa-xmark fa-lg"></i>
        </button>
      </div>

      <!-- Content -->
      <div class="flex flex-col flex-grow p-2 overflow-scroll">
        <div class="text-4xl font-bold text-center">{{ location.title }}</div>

        <div v-if="location.coordinates.latitude" class="mt-1 text-base text-center text-gray-400">
          {{ location.coordinates.latitude.toFixed(5) }}°N
          {{ location.coordinates.longitude.toFixed(5) }}°E
        </div>

        <div class="flex flex-col mt-6 space-y-3">
          <button
            v-if="!webShareAvailable"
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="copyLink"
          >
            <i class="fa-light fa-link fa-lg fa-fw"></i>
            <div>Kopier lenke</div>
          </button>
          <button
            v-if="!webShareAvailable"
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="copyCoordinates"
          >
            <i class="fa-light fa-location-dot fa-lg fa-fw"></i>
            <div>Kopier koordinater</div>
          </button>
          <button
            v-if="webShareAvailable"
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="shareLink"
          >
            🔗 Del lenke
          </button>
          <button
            v-if="webShareAvailable"
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="shareCoordinates"
          >
            🌎 Del koordinater
          </button>
          <button
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="openAppleMaps"
          >
            <i class="fa-light fa-map fa-lg fa-fw"></i>
            <div>Vis i Apple Maps</div>
          </button>
          <button
            class="flex w-full px-5 py-3 space-x-2 text-lg text-left rounded-full text-m ring-gray-300 ring-1"
            @click="openGoogleMaps"
          >
            <i class="fa-light fa-map fa-lg fa-fw"></i>
            <div>Vis i Google Maps</div>
          </button>
        </div>

        <div class="mt-6 text-sm">
          Hvis du deler bilder fra {{ location.title }} på Instagram må du gjerne tagge oss. Følg
          @teltapp for å få med deg alle nye plasser som legges ut.
        </div>
      </div>

      <!-- Navigation bottom -->
      <div class="flex w-full space-x-2">
        <div class="flex items-center flex-grow space-x-2">
          <img
            class="flex-none w-10 h-10 rounded-full"
            :src="location.userPhoto"
            alt="Profile photo"
          />
          <div class="flex-grow overflow-hidden text-xs">
            <div class="font-bold truncate">
              Delt av
              <span v-show="!user || user.uid != location.userId">{{ location.userName }}</span>
              <span v-show="user && user.uid == location.userId">meg</span>
            </div>
            <div>{{ created }}</div>
          </div>
        </div>
        <div v-show="user && user.uid == location.userId" class="flex flex-shrink-0 space-x-2">
          <button
            @click="
              $router.push({
                name: 'edit',
                params: { id: location.id, transition: 'slide-forward' },
              })
            "
            class="w-10 h-10 bg-gray-300 rounded-full"
          >
            <i class="fa-light fa-pen"></i>
          </button>
          <button
            @click="
              $router.push({
                name: 'delete',
                params: { id: location.id, transition: 'slide-forward' },
              })
            "
            class="w-10 h-10 bg-gray-300 rounded-full"
          >
            <i class="fa-light fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </card-container>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import CardContainer from '../components/CardContainer.vue'

export default {
  components: { CardContainer },

  setup() {
    const store = useStore()
    const route = useRoute()

    const location = store.getters.locationById(route.params.id)
    const created = location.created
      .toDate()
      .toLocaleDateString('nb-NO', { year: 'numeric', month: 'long', day: 'numeric' })

    const webShareAvailable = navigator.share ? true : false

    const copyLink = () => {
      let url = String(window.location).replace('/details', '')
      if (navigator.clipboard) navigator.clipboard.writeText(url)
    }

    const copyCoordinates = () => {
      const coordinates =
        location.value.coordinates.latitude.toFixed(5) +
        '°N ' +
        location.value.coordinates.longitude.toFixed(5) +
        '°E'
      if (navigator.clipboard) navigator.clipboard.writeText(coordinates)
    }

    const shareLink = () => {
      let url = String(window.location).replace('/details', '')
      navigator.share({
        url: url,
        title: document.title,
      })
    }

    const shareCoordinates = () => {
      navigator.share({
        text:
          location.value.coordinates.latitude.toFixed(5) +
          '°N ' +
          location.value.coordinates.longitude.toFixed(5) +
          '°E',
      })
    }

    const openAppleMaps = () => {
      const url =
        'https://maps.apple.com/?daddr=' +
        location.value.coordinates.latitude +
        ',' +
        location.value.coordinates.longitude
      window.open(url)
    }

    const openGoogleMaps = () => {
      const url =
        'https://maps.google.com/?q=' +
        location.value.coordinates.latitude +
        ',' +
        location.value.coordinates.longitude
      window.open(url)
    }

    return {
      location,
      copyLink,
      copyCoordinates,
      openAppleMaps,
      openGoogleMaps,
      shareLink,
      shareCoordinates,
      webShareAvailable,
      created,
      user: computed(() => store.getters.user),
    }
  },
}
</script>
